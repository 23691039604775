<template>
  <div v-if="$isLoading">
    <b-row class="no-gutters">
      <b-col>
        <h1 class="font-weight-bold header-main text-uppercase mb-3">
          รายละเอียดบทความ
        </h1>
      </b-col>
    </b-row>
    <div class="bg-white p-3">
      <b-row class="no-gutters">
        <b-col md="6">
          <InputText
            textFloat="ชื่อ"
            placeholder="ชื่อ"
            type="text"
            v-model="form.name"
            isRequired
            :isValidate="$v.form.name.$error"
            :v="$v.form.name"
          />
        </b-col>
      </b-row>
    </div>
    <div class="bg-white p-3 mt-3">
      <b-row class="no-gutters">
        <div class="d-flex justify-content-between w-100">
          <b-input-group class="panel-input-serach">
            <b-form-input
              class="input-serach"
              placeholder="ค้นหา"
              v-model="filter.Search"
              @keyup="handleSearch"
            ></b-form-input>
            <b-input-group-prepend @click="btnSearch">
              <span class="icon-input m-auto pr-2">
                <font-awesome-icon icon="search" title="View" />
              </span>
            </b-input-group-prepend>
          </b-input-group>
          <b-button class="btn-main" @click="openModal()">เพิ่มบทความ</b-button>
        </div>
      </b-row>

      <b-row class="no-gutters mt-3">
        <b-col>
          <b-table
            striped
            responsive
            hover
            :items="form.details"
            :fields="fields"
            :busy="isBusy"
            show-empty
            empty-text="ไม่พบข้อมูล"
            class="table-list"
          >
            <template v-slot:cell(imageUrl)="data">
              <div
                class="position-relative d-flex justify-content-start align-items-center"
              >
                <div
                  class="square-box b-contain"
                  v-bind:style="{
                    'background-image': 'url(' + data.item.imageUrl + ')',
                  }"
                ></div>
              </div>
            </template>
            <template v-slot:cell(name)="data">
              <p class="mb-0 nobreak three-lines">
                {{ data.item.name }}
              </p>
            </template>
            <template v-slot:cell(sortOrder)="data">
              <div
                class="d-flex align-items-center justify-content-center mt-3"
              >
                <InputText
                  textFloat=""
                  placeholder="กรุณากรอกข้อมูล"
                  type="number"
                  v-model="data.item.sortOrder"
                  :isValidate="
                    $v.form.details.$each.$iter[data.index].sortOrder.$error
                  "
                  :v="$v.form.details.$each.$iter[data.index].sortOrder"
                />
              </div>
            </template>
            <template v-slot:cell(action)="data">
              <div class="d-flex justify-content-center">
                <b-button
                  variant="link"
                  class="text-dark pr-3 py-0"
                  @click="openModalDelete(data.item, data.index)"
                >
                  ลบ
                </b-button>
              </div>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row
        class="no-gutters px-3 px-sm-0 pb-3"
        v-if="$v.form.details.$error && !$v.form.details.required"
      >
        <span class="text-error mb-3">กรุณาเพิ่มสินค้า</span>
      </b-row>
      <b-row class="no-gutters px-3 px-sm-0">
        <b-col
          class="form-inline justify-content-center justify-content-sm-between"
        >
          <div class="d-sm-flex">
            <b-pagination
              v-model="filter.PageNo"
              :total-rows="rows"
              :per-page="filter.PerPage"
              class="m-md-0"
              @change="pagination"
              align="center"
            ></b-pagination>
          </div>
          <b-form-select
            class="select-page"
            v-model="filter.PerPage"
            @change="hanndleChangePerpage"
            :options="pageOptions"
          ></b-form-select>
        </b-col>
      </b-row>
    </div>

    <div class="bg-white mt-3">
      <b-row class="mt-3 mb-4">
        <b-col>
          <b-row class="px-3">
            <b-col class="d-flex justify-content-between align-items-center">
              <div class="d-sm-flex my-3 align-items-center">
                <label class="font-weight-bold main-label">
                  การใช้งาน
                  <span class="text-danger">*</span>
                </label>
                <div>
                  <b-form-checkbox
                    switch
                    class="radio-active ml-3"
                    size="lg"
                    v-model="form.status"
                  >
                    <span class="ml-2 main-label">
                      {{ form.status ? "เปิด" : "ปิด" }}
                    </span>
                  </b-form-checkbox>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <b-button class="button btn-cancel ml-2" @click="back()"
                  >ย้อนกลับ</b-button
                >
                <b-button class="btn-main ml-3" @click="checkForm()"
                  >บันทึก</b-button
                >
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <ModalNews ref="ModalNews" @handleData="handleData" />
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
    <ModalAlertConfirm
      msg="ยืนยันการลบ ?"
      :text="modalMessage"
      btnConfirm="ลบ"
      colorBtnConfirm="danger"
      btnCancel="ยกเลิก"
      ref="ModalAlertConfirm"
      @confirm="btnDelete"
    />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import { required, minValue } from "vuelidate/lib/validators";
import ModalNews from "./ModalNews";
import axios from "axios";
import * as moment from "moment/moment";
import { mapState } from "vuex";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";

export default {
  components: {
    InputText,
    ModalAlert,
    ModalAlertError,
    ModalLoading,
    ModalAlertConfirm,
    ModalNews,
  },
  data() {
    return {
      id: this.$route.params.id,
      isDisable: false,
      modalMessage: "",
      form: {},
      isBusy: false,
      rows: 0,
      requestDeleteBanner: {
        id: null,
      },
      duplicateArray: [],
      searchArray: [],
      fields: [
        { key: "imageUrl", label: "ภาพประกอบ", class: "w-100px" },
        {
          key: "name",
          label: "ชื่อบทความ",
          class: "w-100px",
        },
        { key: "blogName", label: "หมวดหมู่", class: "w-100px" },
        { key: "sortOrder", label: "Sort Order", class: "w-100px" },
        {
          key: "action",
          label: "",
        },
      ],
      filter: {
        PageNo: 1,
        PerPage: 10,
        Search: "",
        status: [0, 1],
      },
      pageOptions: [
        { value: 10, text: "10 / หน้า" },
        { value: 30, text: "30 / หน้า" },
        { value: 50, text: "50 / หน้า" },
        { value: 100, text: "100 / หน้า" },
      ],
    };
  },
  validations: {
    form: {
      name: { required },
      details: {
        required,
        $each: {
          sortOrder: { required },
        },
      },
    },
  },
  computed: {
    ...mapState({
      templateId: (state) => state.templateId,
    }),
  },
  created: async function () {
    await this.getData();
  },
  methods: {
    filterData() {
      this.form.details = [];
      this.searchArray = [];
      if (this.filter.Search) {
        for (const e of this.duplicateArray) {
          if (e.name.toLowerCase().includes(this.filter.Search)) {
            this.form.details.push(e);
          }
        }
        this.searchArray = this.form.details;
        this.rows = this.form.details.length;
      } else {
        this.filterPagination();
      }
    },
    filterPagination() {
      this.rows = this.filter.Search
        ? this.searchArray.length
        : this.duplicateArray.length;
      let offset =
        this.filter.PageNo > 0
          ? (this.filter.PageNo - 1) * this.filter.PerPage
          : 0;
      let limit = this.filter.PerPage + offset;
      let arr = [];
      for (
        let index = 0;
        index <
        (this.filter.Search
          ? this.searchArray.length
          : this.duplicateArray.length);
        index++
      ) {
        if (index >= offset && index < limit) {
          arr.push(
            this.filter.Search
              ? this.searchArray[index]
              : this.duplicateArray[index]
          );
        }
      }
      this.form.details = arr;
    },
    handleData(data) {
      for (let index = 0; index < data.length; index++) {
        let find = this.duplicateArray.findIndex((e) => {
          return e.refId === data[index].id;
        });
        if (find === -1) {
          this.duplicateArray.push({
            id: 0,
            refId: data[index].id,
            name: data[index].name,
            imageUrl: data[index].imageUrl,
            blogName: data[index].categoryName,
            sortOrder: data[index].sortOrder,
          });
        }
      }
      this.form.details = this.duplicateArray;
      this.filterPagination();
    },
    back() {
      if (this.templateId) {
        this.$router.push({
          path: `/setting-homepage/details/${this.templateId}`,
        });
      } else {
        this.$router.push({
          path: `/setting-news`,
        });
      }
    },
    openModal() {
      this.$refs.ModalNews.btnSearch();
      this.$refs.ModalNews.checkProduct(this.form.details);
      this.$refs.ModalNews.selectedData = [];
      this.$refs.ModalNews.show();
    },
    getData: async function () {
      this.$isLoading = false;
      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Template/Section/${this.id}`,
        null,
        this.$headersSetting,
        this.filter
      );

      if (data.result == 1) {
        this.form = data.detail;
        this.form.typeId = 5;
        this.duplicateArray = this.form.details ? this.form.details : [];
        this.filterPagination();
      }
      this.$v.form.$reset();
      this.$isLoading = true;
    },
    openModalDelete(value, index) {
      if (this.id == 0 || value.id == 0) {
        let find = this.duplicateArray.findIndex((e) => {
          return e.refId === value.refId;
        });
        this.duplicateArray.splice(find, 1);
        this.form.details.splice(index, 1);
        if (!this.form.details.length) {
          this.filter.PageNo = this.filter.PageNo - 1;
        }
        this.filterPagination();
      } else {
        this.requestDeleteBanner.id = value.id;
        this.modalMessage = "คุณต้องการลบ " + value.name + " ใช่หรือไม่?";
        this.$refs.ModalAlertConfirm.show();
      }
    },
    btnDelete: async function () {
      this.$refs.ModalAlertConfirm.hide();
      let resData = await this.$callApi(
        "delete",
        `${this.$baseUrl}/api/Template/Section/Detail/${this.requestDeleteBanner.id}`,
        null,
        this.$headersSetting,
        null
      );

      this.modalMessage = resData.message;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();
        this.filter.PageNo = 1;
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 1500);
        await this.getData();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    pagination(Page) {
      this.filter.PageNo = Page;
      this.filterPagination();
    },
    hanndleChangePerpage(value) {
      this.filter.PageNo = 1;
      this.filter.PerPage = value;
      this.filterPagination();
    },
    handleSearch() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.filter.PageNo = 1;
        this.filterData();
      }, 800);
    },
    btnSearch() {
      this.filter.PageNo = 1;
      this.filterData();
    },
    checkForm: async function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        if (document.querySelector(".input-custom.error")) {
          this.$nextTick(() => {
            let domRect = document
              .querySelector(".input-custom.error")
              .getBoundingClientRect();
            window.scrollTo(
              domRect.left + document.documentElement.scrollLeft,
              domRect.top + document.documentElement.scrollTop - 150
            );
          });
        }
        return;
      }
      // this.form.details.map((e) => {
      //   e.id = 0;
      //   return e;
      // });
      await this.submit();
    },
    submit: async function () {
      this.isDisable = true;
      this.$refs.modalLoading.show();
      let returnedTarget = JSON.parse(JSON.stringify(this.form));
      returnedTarget.details = this.duplicateArray;
      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Template/Section/Save`,
        null,
        this.$headersSetting,
        returnedTarget
      );

      this.$refs.modalLoading.hide();
      this.modalMessage = data.message;
      this.isDisable = false;
      if (data.result == 1) {
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
          this.back();
        }, 1500);
      } else {
        this.$refs.modalAlertError.show();
      }
    },
  },
};
</script>

<style scoped>
::v-deep .panel-input-serach {
  border: 1px solid #bcbcbc !important;
  max-width: 350px;
}

::v-deep .dropdown-menu-right {
  padding: 0rem !important;
  width: 100% !important;
}

.text-error {
  color: #ff0000;
  font-size: 14px;
}
</style>
